<template>
  <div class="image-editor" tabindex="0" @keyup="keyboardHandler">
    <div class="buttons has-addons">
      <button class="button" v-for="tool in drawingTools" @click="setDrawTool(tool.type)" :class="classForTool(tool.type)">{{ tool.label }}</button>
      <input class="button color-picker" type="color" v-model="color"/>
      <button class="button">Undo</button>
      <button class="button">Redo</button>
    </div>
    <div class="editor-workspace" ref="workspace">
      <div ref="canvasWrapper" class="canvas-wrapper">
        <canvas ref="canvas"></canvas>
      </div>
    </div>
    <div class="buttons has-addons">
      <button @click="saveImage" class="button">Save to Local</button>
      <button class="button">Copy to clipboard</button>
      <button class="button">Generate Link</button>
    </div>
    <a ref="downloader"/>
  </div>
</template>

<script>
import { fabric } from 'fabric'
import { drawingToolForType, DrawingTools } from "./drawing_tools";
import { getCaptureInfo } from "./get_capture_info";

export default {
  data: function () {
    return {
      canvas: null,
      color: "#ff0000",
      isMouseDown: false,
      drawingTools: DrawingTools,
      drawToolType: "",
      drawingTool: null,
      platformInfo: null,
      image: null
    }
  },

  mounted() {
    const ref = this.$refs.canvas;
    const wrapper = this.$refs.canvasWrapper;

    this.canvas = new fabric.Canvas(ref, {
      width: wrapper.clientWidth,
      height: wrapper.clientHeight
    });

    this.canvas.selection = true;
    const self = this;
    this.canvas.on('path:created', () => {
      self.setDrawTool('pen');
    });

    getCaptureInfo()
        .then((info) => {
          const { imageUrl, platformInfo, pixelRatio } = info;

          const scale = 1 / pixelRatio;

          console.log(scale);

          self.platformInfo = platformInfo;

          fabric.Image.fromURL(imageUrl, function(img) {

            console.log(`Loaded image: ${img.width} x ${img.height}`);

            img.set({
              hasControls: false,
              evented: false,
              selectable: false,
              scaleX: scale,
              scaleY: scale,
              left: 0,
              top: 0,
            });
            self.canvas.add(img);
            self.canvas.setDimensions({
              width: img.width * scale,
              height: img.height * scale
            });

            self.image = img;
          });
        })
        .catch(console.error);
  },

  methods: {
    setDrawTool(type){
      this.stopEditingCanvas();
      this.canvas.isDrawingMode = false;
      if(type === this.drawToolType){
        this.drawToolType = "";
        this.drawingTool = null;
      } else {
        this.drawToolType = type;
        if(type === 'pen'){
          this.startEditingCanvas(false);
          this.startFreeDrawing();
          this.drawingTool = null;
        } else {
          this.startEditingCanvas();
          this.drawingTool = drawingToolForType(type);
        }
      }

      this.canvas.renderAll();
    },

    startFreeDrawing(){
      const canvas = this.canvas;
      canvas.isDrawingMode = true;
      canvas.freeDrawingBrush.color = this.color;
      canvas.freeDrawingBrush.width = 3;
      canvas.freeDrawingBrush.strokeUniform = true;
    },

    startEditingCanvas(regMouseHandlers = true){
      if(regMouseHandlers) {
        this.canvas.on('mouse:down', this.onCanvasMouseDown);
        this.canvas.on('mouse:move', this.onCanvasMouseMove);
        this.canvas.on('mouse:up', this.onCanvasMouseUp);
      }
      this.canvas.selection = false;
      this.canvas.forEachObject(o => {
        o.selectable = false;
        o.evented = false;
      });
    },

    stopEditingCanvas(){
      ['down', 'move', 'up'].forEach(action => {
        this.canvas.off(`mouse:${action}`);
      });
      this.canvas.selection = true;
      this.canvas.forEachObject(o => {
        if(o === this.image) {
          console.log("Found image");
          return;
        }
        o.selectable = true;
        o.evented = true;
        o.setCoords();
      });
    },

    classForTool(type){
      if(type === this.drawToolType)
        return 'is-dark';
      return '';
    },

    onCanvasMouseDown(evt){
      this.isMouseDown = true;

      let pointer = this.canvas.getPointer(evt.e);
      this.drawingTool.create(pointer, this.color, this.canvas);
    },

    onCanvasMouseMove(evt){
      if(!this.isMouseDown){
        return;
      }

      let pointer = this.canvas.getPointer(evt.e);
      this.drawingTool.update(pointer);
      this.canvas.renderAll();
    },

    onCanvasMouseUp(evt){
      this.isMouseDown = false;
      this.drawingTool.commit(this.canvas);
      this.canvas.renderAll();
      this.setDrawTool(this.drawToolType);
    },

    keyboardHandler(evt){
      if(evt.keyCode === 8 || evt.keyCode === 46){
        let selection = this.canvas.getActiveObject();
        if(selection.type === 'activeSelection'){
          selection.forEachObject(el => {
            this.canvas.remove(el);
          });
        } else {
          this.canvas.remove(selection);
        }

        this.canvas.discardActiveObject();
        this.canvas.requestRenderAll();
      }
    },

    // Actions to save image
    saveImage(){
      console.log("saveImage");
      const dl = this.$refs.downloader;
      const url = this.canvas.toDataURL({format: 'png', quality: 0.8});
      dl.href = url;
      dl.download = "QAHero - Image";
      dl.click();
    }
  },
}
</script>

<style>

.image-editor {
  outline: none;
}

.editor-workspace {
  padding: 50px;
  background: #666;
}

.color-picker {
  padding: 4px !important;
}

.canvas-wrapper {
  width: 100%;
  user-select: none;
  display: flex;
}

.canvas-container {
  margin: 0 auto;
  height: auto !important;
  display: inline-block;
}

.lower-canvas, .upper-canvas {
  width: 100% !important;
  height: auto !important;
}

.lower-canvas {
  position: relative !important;
}
</style>
