const bulmaToast = require('bulma-toast');

window.toast = bulmaToast;

function renderToasts() {
  if (window.toastInfos) {
    const infos = window.toastInfos;
    for (let i = 0; i < infos.length; i += 1) {
      let info = infos[i];
      bulmaToast.toast({
        message: info.message,
        type: info.class,
        duration: 5000,
        closeOnClick: true,
        position: 'top-center',
        dismissible: true,
        animate: { in: 'fadeIn', out: 'fadeOut' }
      });
    }
    window.toastInfos = null;
  }
}

document.addEventListener('DOMContentLoaded', renderToasts);

document.addEventListener('DOMContentLoaded', () => {
  (document.querySelectorAll('.notification .delete') || []).forEach(($delete) => {
    var $notification = $delete.parentNode;

    $delete.addEventListener('click', () => {
      $notification.parentNode.removeChild($notification);
    });
  });
});
