function getCaptureInfoChrome() {
  const CHROME_ID = 'dpmecdkallnapbbgllhdmeokbmbjjmep';

  return new Promise((res, rej) => {
    try {
      // eslint-disable-next-line no-undef
      chrome.runtime.sendMessage(CHROME_ID, { message: 'getCaptureInfo' }, res);
    } catch (err) {
      console.log(err);
      rej(err);
    }
  });
}

function getCaptureInfoFireFox() {
  return new Promise((res, rej) => {
    try {
      // eslint-disable-next-line no-undef
      getQAHScreenShotFF((jsonRes) => {
        let obj = JSON.parse(jsonRes);
        res(obj);
      });
    } catch (err) {
      console.error(err);
      rej(err);
    }
  });
}

const getCaptureInfo = typeof (chrome) === 'undefined' ? getCaptureInfoFireFox : getCaptureInfoChrome;

export { getCaptureInfo };
