/* eslint-disable max-classes-per-file */
/* eslint-disable class-methods-use-this */
import { fabric } from 'fabric';

class ArrowDrawingTool {
  constructor() {
    this.object = null;
    this.line = null;
    this.triangle = null;
  }

  create(pointer, color, canvas) {
    let points = [pointer.x, pointer.y, pointer.x, pointer.y];
    let line = new fabric.Line(points, {
      strokeWidth: 3,
      fill: color,
      stroke: color,
      originX: 'center',
      originY: 'center',
      type: 'arrow'
    });
    const centerX = (line.x1 + line.x2) / 2;
    const centerY = (line.y1 + line.y2) / 2;
    const deltaX = line.left - centerX;
    const deltaY = line.top - centerY;

    let triangle = new fabric.Triangle({
      left: line.get('x1') + deltaX,
      top: line.get('y1') + deltaY,
      originX: 'center',
      originY: 'center',
      selectable: false,
      pointType: 'arrow_start',
      angle: -45,
      width: 20,
      height: 20,
      fill: color
    });
    this.line = line;
    this.triangle = triangle;

    canvas.add(line, triangle);
  }

  calculateAngle() {
    let {
      x1, y1, x2, y2
    } = this.line;
    let angle = 0; let x; let
      y;
    x = (x2 - x1);
    y = (y2 - y1);
    if (x === 0) {
      angle = (y === 0) ? 0 : (y > 0) ? Math.PI / 2 : Math.PI * 3 / 2;
    } else if (y === 0) {
      angle = (x > 0) ? 0 : Math.PI;
    } else {
      angle = (x < 0) ? Math.atan(y / x) + Math.PI
        : (y < 0) ? Math.atan(y / x) + (2 * Math.PI) : Math.atan(y / x);
    }
    return (angle * 180 / Math.PI + 90);
  }

  update(pointer) {
    this.line.set({
      x2: pointer.x,
      y2: pointer.y
    });
    let line = this.line;
    const centerX = (line.x1 + line.x2) / 2;
    const centerY = (line.y1 + line.y2) / 2;
    const deltaX = line.left - centerX;
    const deltaY = line.top - centerY;
    this.triangle.set({
      left: pointer.x + deltaX,
      top: pointer.y + deltaY,
      angle: this.calculateAngle()
    });
  }

  commit(canvas) {
    let group = new fabric.Group([this.line, this.triangle], {
      lockScalingFlip: true
    });

    canvas.remove(this.line, this.triangle);
    canvas.add(group);
  }
}

class RectDrawingTool {
  constructor() {
    this.object = null;
    this.origin = null;
  }

  create(pointer, color, canvas) {
    this.origin = pointer;
    this.object = new fabric.Rect({
      left: this.origin.x,
      top: this.origin.y,
      width: pointer.x - this.origin.x,
      height: pointer.y - this.origin.y,
      fill: '',
      stroke: color,
      type: 'rect',
      strokeWidth: 3,
      strokeUniform: true
    });

    canvas.add(this.object);
  }

  update(pointer) {
    const left = Math.min(this.origin.x, pointer.x);
    const top = Math.min(this.origin.y, pointer.y);
    const width = Math.abs(this.origin.x - pointer.x);
    const height = Math.abs(this.origin.y - pointer.y);

    this.object.set({
      top, left, width, height
    });
  }

  commit(canvas) {
    if (this.object.width === 0 && this.object.height === 0) {
      canvas.remove(this.object);
      return;
    }
    this.object.perPixelTargetFind = true;
    this.object.targetFindTolerance = 6;
  }
}

class CircleDrawingTool {
  constructor() {
    this.object = null;
    this.origin = null;
  }

  create(pointer, color, canvas) {
    this.object = new fabric.Circle({
      radius: 0,
      top: pointer.y,
      left: pointer.x,
      stroke: color,
      fill: '',
      strokeWidth: 3,
      strokeUniform: true
    });

    canvas.add(this.object);
  }

  update(pointer) {
    const x = this.object.left - pointer.x;
    const y = this.object.top - pointer.y;
    const diff = Math.sqrt(x * x + y * y);
    this.object.set({ radius: diff / 2.3 });
  }

  commit(canvas) {
    if (this.object.height === 0 && this.object.width === 0) {
      canvas.remove(this.object);
    }
  }
}

class TextDrawingTool {
  constructor() {
    this.object = null;
  }

  create(pointer, color, canvas) {
    this.object = new fabric.IText('', {
      fontFamily: 'arial',
      left: pointer.x,
      top: pointer.y,
      stroke: '',
      fill: color
    });

    this.object.on('editing:exited', o => {
      console.log(o);
    });

    canvas.add(this.object);
    canvas.setActiveObject(this.object);
    this.object.enterEditing();
  }

  update() {
  }

  commit() {
  }
}

const DrawingTools = [
  { type: 'arrow', label: 'Arrow' },
  { type: 'text', label: 'Text' },
  { type: 'rect', label: 'Rectangle' },
  { type: 'circle', label: 'Circle' },
  { type: 'pen', label: 'Pen' }
];

function drawingToolForType(type) {
  if (type === 'rect') {
    return new RectDrawingTool();
  } if (type === 'circle') {
    return new CircleDrawingTool();
  } if (type === 'text') {
    return new TextDrawingTool();
  } if (type === 'arrow') {
    return new ArrowDrawingTool();
  }
}

export { drawingToolForType, DrawingTools };
